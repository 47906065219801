import type * as VueRouter from 'vue-router'
export interface Page {
  name: string
  title: string
  path: string
  role:
    | 'talent'
    | 'client'
    | 'common'
    | 'develop'
    | 'public'
    | 'unauthorized'
    | 'emailUnverified'
    | 'maintenance'
  layout: string
  activeUserOnly?: boolean
}
const jobId = 'jobId'
const talentId = 'talentId'
const clientId = 'ISlaokm4p9SRYYzfZYz6SLEmI6n2'
const roomId = 'roomId'
const entryId = 'entryId'
const offerId = 'offerId'
const notificationId = 'notificationId'
const messageId = 'messageId'

export const PAGES: Page[] = [
  // 開発用ページ
  {
    name: 'develop',
    title: '開発者専用',
    path: '/develop',
    role: 'develop',
    layout: 'default',
    activeUserOnly: false,
  },
  // メンテナンス用ページ
  {
    name: 'maintenance',
    title: 'メンテナンス中',
    path: '/maintenance',
    role: 'maintenance',
    layout: 'maintenance',
  },
  // Talent (キャスト)
  {
    name: 'talent-home',
    title: '案件一覧',
    path: '/talent',
    role: 'talent',
    layout: 'top',
    activeUserOnly: true,
  },
  {
    name: 'talent-talents',
    title: 'キャスト一覧',
    path: '/talent/talents',
    role: 'talent',
    layout: 'top',
    activeUserOnly: true,
  },
  {
    name: 'talent-talents-id',
    title: 'キャスト詳細',
    path: `/talent/talents/${talentId}`,
    role: 'talent',
    layout: 'detail',
    activeUserOnly: true,
  },
  {
    name: 'talent-jobs',
    title: '案件一覧',
    path: '/talent/jobs',
    role: 'talent',
    layout: 'top',
    activeUserOnly: true,
  },
  {
    name: 'talent-jobs-id',
    title: '案件詳細',
    path: `/talent/jobs/${jobId}`,
    role: 'talent',
    layout: 'detail',
    activeUserOnly: true,
  },
  {
    name: 'talent-jobs-id-apply',
    title: '案件エントリー',
    path: `/talent/jobs/${jobId}/apply`,
    role: 'talent',
    layout: 'detail',
    activeUserOnly: true,
  },
  {
    name: 'talent-jobs-id-apply-done',
    title: '案件エントリー完了',
    path: `/talent/jobs/${jobId}/apply/done`,
    role: 'talent',
    layout: 'done',
    activeUserOnly: true,
  },
  {
    name: 'talent-mypage',
    title: 'マイページ',
    path: `/talent/mypage`,
    role: 'talent',
    layout: 'top',
    activeUserOnly: false,
  },
  {
    name: 'talent-mypage-profile',
    title: 'ポートフォリオ',
    path: `/talent/mypage/profile`,
    role: 'talent',
    layout: 'detail',
    activeUserOnly: false,
  },
  {
    name: 'talent-mypage-profile-edit_01',
    title: '基本プロフィール登録',
    path: `/talent/mypage/profile/edit_01`,
    role: 'talent',
    layout: 'detail',
    activeUserOnly: false,
  },
  {
    name: 'talent-mypage-profile-edit_02',
    title: 'ポートフォリオ登録（詳細プロフィール登録）',
    path: `/talent/mypage/profile/edit_02`,
    role: 'talent',
    layout: 'edit',
    activeUserOnly: false,
  },
  {
    name: 'talent-mypage-profile-done',
    title: 'プロフィール登録完了',
    path: `/talent/mypage/profile/done`,
    role: 'talent',
    layout: 'done',
    activeUserOnly: false,
  },
  {
    name: 'talent-mypage-profile-confirmation',
    title: 'ポートフォリオ登録完了（モーダル）',
    path: `/talent/mypage/profile/confirmation`,
    role: 'talent',
    layout: 'default',
    activeUserOnly: false,
  },
  {
    name: 'talent-mypage-offers',
    title: 'お問い合わせ一覧',
    path: `/talent/mypage/offers`,
    role: 'talent',
    layout: 'default',
    activeUserOnly: false,
  },
  {
    name: 'talent-mypage-offers-id',
    title: 'お問い合わせ',
    path: `/talent/mypage/offers/id`,
    role: 'talent',
    layout: 'default',
    activeUserOnly: false,
  },
  {
    name: 'talent-mypage-entries',
    title: 'エントリー一覧',
    path: `/talent/mypage/entries`,
    role: 'talent',
    layout: 'default',
    activeUserOnly: false,
  },
  {
    name: 'talent-mypage-entries-id',
    title: 'エントリー詳細',
    path: `/talent/mypage/entries/${entryId}`,
    role: 'talent',
    layout: 'detail',
    activeUserOnly: false,
  },
  {
    name: 'talent-mypage-bookmarks',
    title: 'ブックマーク一覧',
    path: `/talent/mypage/bookmarks`,
    role: 'talent',
    layout: 'default',
    activeUserOnly: false,
  },
  {
    name: 'talent-mypage-messages',
    title: 'メッセージボックス',
    path: `/talent/mypage/messages`,
    role: 'talent',
    layout: 'default',
    activeUserOnly: false,
  },
  {
    name: 'talent-mypage-messages-room',
    title: 'メッセージ詳細',
    path: `/talent/mypage/messages/${messageId}`,
    role: 'talent',
    layout: 'message',
    activeUserOnly: false,
  },
  {
    name: 'talent-mypage-reviews',
    title: 'レビュー一覧',
    path: `/talent/mypage/reviews`,
    role: 'talent',
    layout: 'default',
    activeUserOnly: false,
  },
  {
    name: 'talent-clients-id',
    title: 'レビュー一覧',
    path: `/talent/clients/${clientId}`,
    role: 'talent',
    layout: 'default',
    activeUserOnly: false,
  },
  {
    name: 'talent-clients-id-reviews',
    title: 'レビュー一覧',
    path: `/talent/clients/${clientId}/reviews`,
    role: 'talent',
    layout: 'default',
    activeUserOnly: false,
  },
  {
    name: 'talent-clients-id-reviews-add',
    title: 'レビュー登録',
    path: `/talent/client/${clientId}/reviews/add`,
    role: 'talent',
    layout: 'detail',
    activeUserOnly: false,
  },
  {
    name: 'talent-clients-id-reviews-done',
    title: 'レビュー登録完了',
    path: `/talent/client/${clientId}/reviews/done`,
    role: 'talent',
    layout: 'done',
    activeUserOnly: false,
  },

  {
    name: 'talent-mypage-review-tasks',
    title: 'レビュー待ち一覧',
    path: `/talent/mypage/review-tasks`,
    role: 'talent',
    layout: 'default',
  },
  {
    name: 'talent-notification',
    title: 'お知らせ一覧',
    path: `/talent/notification`,
    role: 'talent',
    layout: 'news',
    activeUserOnly: false,
  },
  {
    name: 'talent-notification-id',
    title: 'お知らせ詳細',
    path: `/talent/notification/${notificationId}`,
    role: 'talent',
    layout: 'default',
    activeUserOnly: false,
  },
  {
    name: 'talent-settings',
    title: 'その他（設定）',
    path: `/talent/settings`,
    role: 'talent',
    layout: 'top',
    activeUserOnly: false,
  },
  {
    name: 'talent-settings-snsterm',
    title: 'SNS利用に関する遵守事項',
    path: `/talent/settings/snsterm`,
    role: 'talent',
    layout: 'default',
    activeUserOnly: false,
  },
  // Client
  {
    name: 'client-home',
    title: 'キャスト一覧',
    path: '/client',
    role: 'client',
    layout: 'top',
  },
  {
    name: 'client-talents',
    title: 'キャスト一覧',
    path: '/client/talents',
    role: 'client',
    layout: 'top',
  },
  {
    name: 'client-jobs',
    title: '案件一覧',
    path: '/client/jobs',
    role: 'client',
    layout: 'top',
  },
  {
    name: 'client-talents-id',
    title: 'キャスト詳細',
    path: `/client/talents/${talentId}`,
    role: 'client',
    layout: 'detail',
  },
  {
    name: 'client-talents-id-offer',
    title: 'キャストお問い合わせ',
    path: `/client/talents/${talentId}/offer`,
    role: 'client',
    layout: 'detail',
  },
  {
    name: 'client-talents-id-offer-done',
    title: 'キャスト問い合わせ開始',
    path: `/client/talents/${talentId}/offer/done`,
    role: 'client',
    layout: 'done',
  },
  {
    name: 'client-mypage',
    title: 'マイページ',
    path: `/client/mypage`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-mypage-jobs',
    title: '登録案件一覧',
    path: `/client/mypage/jobs`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-mypage-jobs-id',
    title: '案件詳細',
    path: `/client/mypage/jobs/${jobId}`,
    role: 'client',
    layout: 'detail',
  },
  {
    name: 'client-mypage-jobs-id-edit',
    title: '案件編集',
    path: `/client/mypage/jobs/${jobId}/edit`,
    role: 'client',
    layout: 'detail',
  },
  {
    name: 'client-mypage-jobs-id-done',
    title: '案件編集完了',
    path: `client/mypage/jobs/${jobId}/done`,
    role: 'client',
    layout: 'done',
  },
  {
    name: 'client-mypage-jobs-add',
    title: '案件登録',
    path: `/client/mypage/jobs/add`,
    role: 'client',
    layout: 'detail',
  },
  {
    name: 'client-mypage-jobs-add-confirm',
    title: '案件追加確認',
    path: `/client/mypage/jobs/add/confirm`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-mypage-jobs-add-done',
    title: '案件追加完了',
    path: `/client/mypage/jobs/add/done`,
    role: 'client',
    layout: 'done',
  },
  {
    name: 'client-mypage-messages',
    title: 'メッセージボックス',
    path: `/client/mypage/messages`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-mypage-messages-room',
    title: 'メッセージ詳細',
    path: `/client/mypage/messages/${roomId}`,
    role: 'client',
    layout: 'message',
  },
  {
    name: 'client-mypage-offers',
    title: 'お問い合わせ一覧',
    path: `/client/mypage/offers`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-mypage-offers-id',
    title: 'お問い合わせ',
    path: `/client/mypage/offers/${offerId}`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-mypage-offers-id-fee-edit',
    title: '報酬変更',
    path: `/client/mypage/offers/${offerId}/fee/edit`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-mypage-offers-id-fee-done',
    title: '報酬変更完了',
    path: `/client/mypage/offers/${offerId}/fee/done`,
    role: 'client',
    layout: 'done',
  },
  {
    name: 'client-mypage-offers-id-schedule-edit',
    title: '終了予定日変更',
    path: `/client/mypage/offers/${offerId}/schedule/edit`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-mypage-offers-id-schedule-done',
    title: '終了予定日変更完了',
    path: `/client/mypage/offers/${offerId}/schedule/done`,
    role: 'client',
    layout: 'done',
  },
  {
    name: 'client-mypage-entries',
    title: 'エントリー一覧',
    path: `/client/mypage/entries`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-mypage-entries-id',
    title: 'エントリー詳細',
    path: `/client/mypage/entries/${entryId}`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-mypage-entries-id-fee-edit',
    title: '報酬変更',
    path: `/client/mypage/entries/${entryId}/fee/edit`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-mypage-entries-id-fee-done',
    title: '報酬変更完了',
    path: `/client/mypage/entries/${entryId}/fee/done`,
    role: 'client',
    layout: 'done',
  },
  {
    name: 'client-mypage-entries-id-schedule-edit',
    title: '終了予定日変更',
    path: `/client/mypage/entries/${entryId}/schedule/edit`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-mypage-entries-id-schedule-done',
    title: '終了予定日変更完了',
    path: `/client/mypage/entries/${entryId}/schedule/done`,
    role: 'client',
    layout: 'done',
  },
  {
    name: 'client-mypage-company-edit',
    title: '会社情報設定',
    path: `/client/mypage/company/edit`,
    role: 'client',
    layout: 'detail',
  },
  {
    name: 'client-mypage-company-done',
    title: '会社情報設定完了',
    path: `/client/mypage/company/done`,
    role: 'client',
    layout: 'done',
  },
  {
    name: 'client-mypage-favorites',
    title: 'お気に入り一覧',
    path: `/client/mypage/favorites`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-notification',
    title: 'お知らせ一覧',
    path: `/client/notification`,
    role: 'client',
    layout: 'news',
  },
  {
    name: 'client-mypage-reviews',
    title: 'レビュー一覧',
    path: `/client/mypage/reviews`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-talents-id-reviews',
    title: 'レビュー一覧',
    path: `/client/talents/${talentId}/reviews`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-talents-id-reviews-add',
    title: 'レビュー登録',
    path: `/client/talents/${talentId}/reviews/add`,
    role: 'client',
    layout: 'detail',
  },
  {
    name: 'client-talents-id-reviews-done',
    title: 'レビュー登録完了',
    path: `/client/talents/${talentId}/reviews/done`,
    role: 'client',
    layout: 'done',
  },
  {
    name: 'client-mypage-review-tasks',
    title: 'レビュー待ち一覧',
    path: `/client/mypage/review-tasks`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-notification-id',
    title: 'お知らせ詳細',
    path: `/client/notification/${notificationId}`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-settings',
    title: '設定',
    path: `/client/settings`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-settings-terms',
    title: '利用規約',
    path: `/client/settings/terms`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-settings-bill-edit',
    title: '請求設定',
    path: `/client/settings/bill/edit`,
    role: 'client',
    layout: 'default',
  },
  {
    name: 'client-settings-bill-done',
    title: '請求設定完了',
    path: `/client/settings/bill/done`,
    role: 'client',
    layout: 'done',
  },
  {
    name: 'client-mypage-settings-help',
    title: 'ご利用ガイド・ヘルプ',
    path: `/client/mypage/settings/help`,
    role: 'client',
    layout: 'default',
  },
  // Common
  {
    name: 'initial',
    title: '初回タイプ選択',
    path: '/initial',
    role: 'common',
    layout: 'initial',
  },
  {
    name: 'registration',
    title: 'ユーザー登録',
    path: '/registration',
    role: 'unauthorized',
    layout: 'login',
  },
  {
    name: 'registration-confirmation',
    title: 'ユーザー登録',
    path: '/registration/confirmation',
    role: 'emailUnverified',
    layout: 'done',
  },
  {
    name: 'registration-email',
    title: 'メール認証',
    path: '/registration/email',
    role: 'emailUnverified',
    layout: 'login',
  },
  {
    name: 'login',
    title: 'ログイン',
    path: '/login',
    role: 'unauthorized',
    layout: 'login',
  },
  {
    name: 'terms-privacy',
    title: 'プライバシーポリシー',
    path: '/terms/privacy',
    role: 'common',
    layout: 'default',
  },
  {
    name: 'terms-legal',
    title: '特定商取引法',
    path: '/terms/legal',
    role: 'common',
    layout: 'default',
  },
  {
    name: 'terms-tos',
    title: '利用規約',
    path: '/terms/tos',
    role: 'common',
    layout: 'default',
  },
  {
    name: 'company',
    title: '企業情報',
    path: '/company',
    role: 'common',
    layout: 'default',
  },
  {
    name: 'usermgmt',
    title: '認証',
    path: '/usermgmt',
    role: 'public',
    layout: 'login',
  },
  {
    name: 'password-reset',
    title: 'パスワードリセット',
    path: '/password/reset',
    role: 'public',
    layout: 'login',
  },
  {
    name: 'password-reset-done',
    title: 'パスワードリセット完了',
    path: '/password/reset/done',
    role: 'public',
    layout: 'done',
  },
  {
    name: 'mail-edit',
    title: 'ログイン用メールアドレス変更',
    path: '/mail/edit',
    role: 'common',
    layout: 'login',
  },
  {
    name: 'password-edit',
    title: 'パスワード変更',
    path: '/password/edit',
    role: 'common',
    layout: 'login',
  },
  {
    name: 'password-edit-done',
    title: 'パスワード変更完了',
    path: '/password/edit/done',
    role: 'common',
    layout: 'done',
  },
  {
    name: 'withdrawal',
    title: '退会',
    path: 'withdrawal',
    role: 'common',
    layout: 'login',
  },
  {
    name: 'withdrawal-done',
    title: '退会完了',
    path: 'withdrawal/done',
    role: 'public',
    layout: 'login',
  },
  {
    name: 'destination-edit',
    title: '通知先メールアドレス変更',
    path: '/destination/edit',
    role: 'common',
    layout: 'login',
  },
]

export function isTalentOnly(route: VueRouter.Route) {
  return PAGES.filter((page) => page.role === 'talent').some(
    (page) => page.name === route.name
  )
}

export function isClientOnly(route: VueRouter.Route) {
  return PAGES.filter((page) => page.role === 'client').some(
    (page) => page.name === route.name
  )
}

export function isUnauthorizedOnly(route: VueRouter.Route) {
  return PAGES.filter((page) => page.role === 'unauthorized').some(
    (page) => page.name === route.name
  )
}

export function publicRoute(route: VueRouter.Route) {
  return PAGES.filter((page) => page.role === 'public').some(
    (page) => page.name === route.name
  )
}

export function emailUnverifiedRoute(route: VueRouter.Route) {
  return PAGES.filter((page) => page.role === 'emailUnverified').some(
    (page) => page.name === route.name
  )
}

export function unCertificatedClientRoute(route: VueRouter.Route) {
  return PAGES.filter(
    (page) =>
      page.role === 'common' ||
      page.name === 'client-mypage' ||
      page.name.includes('client-mypage-company')
  ).some((page) => page.name === route.name)
}

export function isActiveTalentOnly(route: VueRouter.Route) {
  return (
    isTalentOnly(route) &&
    PAGES.filter((page) => !page.activeUserOnly).some(
      (page) => page.name === route.name
    )
  )
}

export function isMaintenanceOnly(route: VueRouter.Route) {
  return PAGES.filter((page) => page.role === 'maintenance').some(
    (page) => page.name === route.name
  )
}

// Tab用
export function isHomeTab(route: VueRouter.Route) {
  return route.name === 'client-home' || route.name === 'talent-home'
}

export function isNewsTab(route: VueRouter.Route) {
  return route.name?.includes('notification')
}

export function isCastTab(route: VueRouter.Route) {
  return route.name?.includes('talents')
}

export function isMypageTab(route: VueRouter.Route) {
  return route.name?.includes('mypage')
}

export function isJobTab(route: VueRouter.Route) {
  return route.name?.includes('jobs')
}

export function isSettingsTab(route: VueRouter.Route) {
  return (
    route.name?.includes('settings') ||
    route.name?.includes('terms') ||
    route.name?.includes('company')
  )
}
