"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonTalentWithLiked = exports.CommonTalent = void 0;
class CommonTalent {
    constructor(init, id) {
        Object.assign(this, { ...init, id });
    }
    static toFirestore(data) {
        return {
            birthday: data.birthday,
            discography: data.discography,
            gender: data.gender,
            industry: data.industry,
            introduction: data.introduction,
            keywords: data.keywords,
            name: data.name,
            kana: data.kana,
            prefecture: data.prefecture,
            singingRange: data.singingRange,
            sizes: data.sizes,
            sns: data.sns,
            speciality: data.speciality,
            thumbnail: data.thumbnail,
            liked: data.liked,
            pr: data.pr,
            isPublished: data.isPublished,
            lastId: data.lastId,
            deletedAt: data.deletedAt,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            reviews: data.reviews,
            latestReviews: data.latestReviews,
            taCode: data.taCode,
            belongingTo: data.belongingTo,
            profileImages: data.profileImages,
            isSuspended: data.isSuspended,
            viewOrder: data.viewOrder,
            replicatedAt: data.replicatedAt,
            isFixedOrder: data.isFixedOrder,
        };
    }
}
exports.CommonTalent = CommonTalent;
class CommonTalentWithLiked extends CommonTalent {
    constructor(init, likedTalent) {
        super(init, init.id);
        this.likedTalent = likedTalent;
    }
}
exports.CommonTalentWithLiked = CommonTalentWithLiked;
